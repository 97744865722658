<template>
	<div class="container">
		<div class="search" style="display:flex">
			<el-form ref="form" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
				<el-form-item>
					<el-input v-model.trim="search.appid" @change="Search" placeholder="终端编号"></el-input>
				</el-form-item>
				<el-form-item>
					<el-input v-model.trim="search.order_id" @change="Search" placeholder="订单编号"></el-input>
				</el-form-item>
				<el-form-item>
					<el-select v-model="search.product_id" clearable @change="Search" placeholder="套餐唯一编号">
						<el-option v-for="item in packlist" :key="item.SetMeal.code" :label="item.SetMeal.name"
							:value="item.SetMealProductModel.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="search.status" clearable @change="Search" placeholder="状态">
						<el-option label="全部" :value="-1"></el-option>
						<el-option label="未支付" :value="0"></el-option>
						<el-option label="已支付" :value="1"></el-option>
						<el-option label="已取消" :value="2"></el-option>
						<el-option label="已过期" :value="3"></el-option>
						<el-option label="已删除" :value="4"></el-option>
						<el-option label="待收款" :value="6"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-input v-model.trim="search.subscribe_id" @change="Search" placeholder="订阅编号"></el-input>
				</el-form-item>
				<el-form-item>
					<el-input v-model.trim="search.uid" @change="Search" placeholder="用户编号"></el-input>
				</el-form-item>
				<el-form-item>
					<el-input v-model.trim="search.uuid" @change="Search" placeholder="设备编号"></el-input>
				</el-form-item>
			</el-form>
		</div>
		<!-- 列表 -->
		<el-table border v-bind:data="list" :tooltip-effect="light">
			<el-table-column label="用户唯一编码">
				<template v-slot="scope">
					<span>{{ scope.row.uid }}</span>
				</template>
			</el-table-column>
			<el-table-column label="设备唯一编码" show-overflow-tooltip>
				<template v-slot="scope">
					<span>{{ scope.row.uuid }}</span>
				</template>
			</el-table-column>
			<!-- <el-table-column label="app唯一标识">
				<template v-slot="scope">
					<span>{{ scope.row.appid }}</span>
				</template>
			</el-table-column> -->
			<el-table-column label="循环付款id">
				<template v-slot="scope">
					<span>{{ scope.row.subscribe_id }}</span>
				</template>
			</el-table-column>
			<el-table-column label="订单号">
				<template v-slot="scope">
					<span>{{ scope.row.order_id }}</span>
				</template>
			</el-table-column>
			<el-table-column label="付款状态">
				<template v-slot="scope">
					<span style="display: inline-block;float: left;">
						<el-tag v-if="scope.row.is_test==1">测试</el-tag>
					</span>
					<span>{{ ChangeStatus(scope.row.status) }}</span>
				</template>
			</el-table-column>
			<el-table-column label="付款类型" align="right">
				<template v-slot="scope">
					<span
						style="display: inline-block;float: left;text-align: center;">{{ ChangePayType(scope.row.pay_type)}}</span>
					<span style="display: inline-block;float: rigth;">
						<el-tag v-if="scope.row.is_sub==0">非订阅</el-tag>
						<el-tag v-else type="success">订阅</el-tag>
					</span>
				</template>
			</el-table-column>
			<!-- <el-table-column label="是否测试">
				<template v-slot="scope">
					<span>{{ scope.row.is_test==0?'生产订单':scope.row.is_test==1?'测试订单':'未知' }}</span>
				</template>
			</el-table-column> -->
			<el-table-column label="套餐名称">
				<template v-slot="scope">
					<span>{{ scope.row.product_name }}</span>
				</template>
			</el-table-column>
			<el-table-column label="金额/币种">
				<template v-slot="scope">
					<span>{{ scope.row.amount }}</span>/
					<span>{{ scope.row.currency }}</span>
				</template>
			</el-table-column>
			<el-table-column label="提交时间">
				<template v-slot="scope">
					<span>{{ dateFormat(scope.row.submit_time) }}</span>
				</template>
			</el-table-column>
			<el-table-column label="付款时间">
				<template v-slot="scope">
					<span>{{ dateFormat(scope.row.pay_time) }}</span>
				</template>
			</el-table-column>
			<el-table-column label="交易号">
				<template v-slot="scope">
					<span>{{ scope.row.payer_id }}</span>
				</template>
			</el-table-column>
			<el-table-column label="操作">
				<template v-slot="scope">
					<el-button type="info" size="small" @click="CloudInfo(scope.row)">云存储详情</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination @current-change="handleCurrentChange" v-model:currentPage="search.page" :page-size="search.limit"
			layout="total, prev, pager, next" :total="total">
		</el-pagination>

		<el-dialog title="订单详情" v-model="dialogFormVisible" width="1100px">
			<el-form :model="form" :inline="true" :rules="rules" ref="form">
				<el-form-item label="用户唯一编码" prop="name" label-width="110px">
					<el-input v-model="form.uid" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="设备唯一编码" label-width="110px">
					<el-input v-model="form.uuid" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="app唯一标识" prop="name" label-width="110px">
					<el-input v-model="form.appid" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="订单号" label-width="110px">
					<el-input v-model="form.order_id" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="循环付款id" label-width="110px">
					<el-input v-model="form.subscribe_id" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="套餐唯一编号" prop="name" label-width="110px">
					<el-input v-model="form.product_id" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="付款状态" label-width="110px">
					<el-input v-model="form.status" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="说明" prop="name" label-width="110px">
					<el-input v-model="form.description" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="付款类型" label-width="110px">
					<el-input v-model="form.pay_type" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="是否订阅是" prop="name" label-width="110px">
					<el-input v-model="form.is_sub" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="是否测试" label-width="110px">
					<el-input v-model="form.is_test" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="套餐名称" prop="name" label-width="110px">
					<el-input v-model="form.product_name" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="付款金额" label-width="110px">
					<el-input v-model="form.amount" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="币种" prop="name" label-width="110px">
					<el-input v-model="form.currency" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="订单提交时间" label-width="110px">
					<el-input v-model="form.submit_time" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="付款时间" prop="name" label-width="110px">
					<el-input v-model="form.pay_time" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="交易号" label-width="110px">
					<el-input v-model="form.payer_id" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="dialogFormVisible = false">取 消</el-button>
				</span>
			</template>
		</el-dialog>

		<el-dialog title="云存储详情" v-model="cloudFormVisible" width="800px">
			<el-form :model="cloudform" :rules="rules" ref="form">
				<el-form-item label="用户id" prop="name" label-width="140px">
					<el-input disabled v-model="cloudform.uid" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="设备uuid" label-width="140px">
					<el-input disabled v-model="cloudform.uuid" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="唯一编号" prop="name" label-width="140px">
					<el-input disabled v-model="cloudform.schema" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="订单号" label-width="140px">
					<el-input disabled v-model="cloudform.order_id" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="套餐开始时间" prop="name" label-width="140px">
					<el-input disabled v-model="cloudform.start_time" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="套餐结束时间" label-width="140px">
					<el-input disabled v-model="cloudform.end_time" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="套餐内云存储时间" prop="name" label-width="140px">
					<el-input disabled v-model="cloudform.file_time" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="状态" label-width="140px">
					<el-input disabled v-model="cloudform.status" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="是否为事件" prop="name" label-width="140px">
					<el-input disabled v-model="cloudform.is_event" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="cloudFormVisible = false">取 消</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>

<script type="text/ecmascript-6">
	import {
		pay_type,
		order_status
	} from '../../../assets//js/dictionary'
	import api from '../../../axios/pack'
	import util from '../../../util/util'
	export default {
		name: '',
		mixins: [],
		components: {},
		props: {},
		data() {
			return {
				title: '',
				search: {
					page: 1,
					limit: 10,
					appid: '',
					order_id: '',
					product_id: '',
					status: -1,
					subscribe_id: '',
					uid: '',
					uuid: '',
				},
				list: [],
				edit: false,
				dialogFormVisible: false,
				form: {
					uid: '',
					uuid: '',
					appid: '',
					order_id: '',
					account: '',
					subscribe_id: '',
					product_id: '',
					status: '',
					description: '',
					pay_type: '',
					is_sub: '',
					is_test: '',
					product_name: '',
					amount: '',
					currency: '',
					submit_time: '',
					pay_time: '',
					payer_id: '',
				},
				rules: {
					name: [{
						required: true,
						message: '请输入页面名称',
						trigger: 'blur'
					}]
				},
				total: 0,
				cloudFormVisible: false,
				cloudform: {
					uid: '',
					uuid: '',
					schema: '',
					order_id: '',
					start_time: '',
					end_time: '',
					file_time: '',
					status: '',
					is_event: '',
				},
				packlist: []
			}
		},
		computed: {},
		watch: {},
		methods: {
			GetPack() {
				api.PackageList({
					page: 1,
					limit: 100
				}).then(res => {
					if (res.data.code == 200) {
						this.packlist = res.data.data.data
						return
					}
					this.$message.error('获取失败' + res.data.msg)
				})
			},
			handleCurrentChange(val) {
				this.search.page = val
				this.Search()
			},
			Search() {
				this.api.OrderList(this.search).then(res => {
					if (res.data.code == 200) {
						this.list = res.data.data.data
						this.total = res.data.data.page_info.total
						return
					}
					this.$message.error('获取失败' + res.data.msg)
				})
			},
			OrderInfo(item) {
				this.api.OrderInfo({
					code: item.order_id
				}).then(res => {
					if (res.data.code == 200) {
						this.dialogFormVisible = true
						this.form = res.data.data
						this.form.status = util.handleValueDisplay(order_status, res.data.data.status)
						this.form.pay_type = util.handleValueDisplay(pay_type, res.data.data.pay_type)
						this.form.is_sub = res.data.data.is_sub == 0 ? '未订阅' : res.data.data.is_sub == 1 ? '订阅' :
							'未知'
						this.form.is_test = res.data.data.is_test == 0 ? '生产订单' : res.data.data.is_test == 1 ?
							'测试订单' : '未知'
						this.form.submit_time = util.dateFormat(res.data.data.submit_time)
						this.form.pay_time = util.dateFormat(res.data.data.pay_time)
						return
					}
					this.$message.error('获取失败' + res.data.msg)
				})
			},
			CloudInfo(item) {
				this.api.OrderCloudInfo({
					code: item.order_id
				}).then(res => {
					if (res.data.code == 200) {
						this.cloudFormVisible = true
						this.cloudform = res.data.data
						this.cloudform.start_time = util.dateFormat(res.data.data.start_time)
						this.cloudform.end_time = util.dateFormat(res.data.data.end_time)
						this.cloudform.file_time = res.data.data.file_time + '天'
						this.cloudform.status = res.data.data.status == 0 ? '未订阅' : res.data.data.status == 1 ?
							'订阅' : '未知'
						this.cloudform.is_event = res.data.data.is_event == 0 ? '全时' : res.data.data.is_event ==
							1 ? '事件录影' : '未知'
						return
					}
					this.$message.error('获取失败' + res.data.msg)
				})
			},
			ChangeStatus(item) {
				return util.handleValueDisplay(order_status, item)
			},
			ChangePayType(item) {
				return util.handleValueDisplay(pay_type, item)
			},
			dateFormat(time) {
				return util.dateFormat(time)
			}
		},
		filters: {},
		mounted() {
			this.Search()
		},
		created() {
			this.GetPack()
		}
	}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
